/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Base Styles */
.header-main-container {
  height: 12vh;
  width: 100%;
  background-color: rgb(15, 15, 128);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.header-logo-container, .header-menu-container {
  display: flex;
  align-items: center;
}

.header-icons {
  color: white;
  font-size: 3rem; /* Increased size for icons */
}

.header-title {
  color: white;
  margin-left: 10px;
  font-size: 3rem; /* Increased size for title */
  white-space: nowrap;
}

.header-icons-hover:hover {
  color: black;  /* Ensure text color remains consistent */
  transform: scale(1.05);  /* Slightly scale up the icon */
}

/* Info Block CSS */
.info-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.info-lane {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.info-block {
  width: 90%;
  margin: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 24px;
  transition: transform 0.3s ease;
}

.info-block:hover {
  transform: scale(1.05);
}

.accordion-header {
  color: aliceblue;
  cursor: pointer;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 16px;
  text-align: center;
}

.accordion-details {
  display: none;
  /* Other styles */
}

.accordion-details.expanded {
  display: block;
  margin-top: 3px;
  border-radius: 13px;
  transition: transform 0.3s ease;
  /* Other styles for expanded state */
}


.info-print-container{
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 10px;
  background-color: grey;
  padding: 2vh;
  margin: 10px;
  height: 100%;
  width: 100%;
  border-radius: 3px;
}

.xgboost-print-container{
  grid-area: 1 / 1 / 2 / 2;
}

.rnn-print-container{
  grid-area: 1 / 2 / 2 / 3; 
}

.regressor-print-container{
  grid-area: 2 / 1 / 3 / 2; 
}

.arima-print-container{
  grid-area: 2 / 2 / 3 / 3;
}


@media (max-width: 768px) {
  .info-print-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: grey;
    padding: 2vh;
    margin: 10px;
    height: auto; /* Adjust height as needed for mobile */
    width: 100%;
    border-radius: 5px;
  }

  .xgboost-print-container,
  .rnn-print-container,
  .regressor-print-container,
  .arima-print-container {
    grid-area: auto; /* Reset grid-area to auto */
  }
}


.details-container > h3 {
  padding-left: 1rem;
  color: aliceblue;
}

/* Loader CSS */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Footer CSS */
.footer-container {
  height: 5rem;
  background-color: rgb(15, 15, 128);
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-text {
  color: aliceblue;
  font-size: medium;
  text-align: center;
}

/* Media Queries */

/* Tablet and above */
@media (min-width: 768px) {
  .header-phase-shower {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .phase-circle {
    height: 2rem;
    width: 2rem;
    padding-right: 5px;
  }
  .main-phase-container{
    width: 50%;
  }
}

/* Mobile devices */
@media (max-width: 768px) {
  .header-title {
    font-size: 2.5rem; /* Adjusted size for smaller screens */
  }

  .header-icons {
    font-size: 3rem; /* Adjusted size for smaller screens */
  }
  .main-phase-container{
    width: 80%; /* Changed width for mobile screens */
  }
}

/* Smaller mobile devices */
@media (max-width: 480px) {
  .header-main-container {
    flex-direction: column;
    height: auto;
    padding: 20px 10px;
  }

  .header-logo-container, .header-menu-container {
    justify-content: center;
    width: 100%;
    margin: 10px 0;
  }

  .header-title {
    font-size: 2.5rem; /* Adjusted size for smallest screens */
    text-align: center;
  }

  .header-icons {
    font-size: 3rem; /* Adjusted size for smallest screens */
  }

  .main-phase-container{
    width: 80%; /* Ensure consistent width for smallest screens */
  }
}
/* APP MAIN CSS */
.app-main-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* PHASE CSS */
.main-phase-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
 
  margin-top: 10px;
  background-color: grey;
  border-radius: 5px;
  border: 10px solid darkgrey;
}

.phase-container{
  margin: 10px;
  text-align: center;
  color: antiquewhite;
  font-size: large;
}

/* LOGIN CSS */

/* Login.css */

/* ERROR PART */
.login-error-container{
  background-color: lightcoral;
  color: aliceblue;
  width: calc(50% - 24px); /* Adjust for padding */
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 15px;
  text-align: center;
}

.password-input-container {
  position: relative;
  width: 100%;
}

.eye-icon {
  position: absolute;
  right: 1.8rem;
  top: 43%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.5rem;
}

/* Main container */
.login-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh; /* Full viewport height */
  background-color: #f0f0f0;
}

/* Login container */
.login-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  max-width: 80%; /* Responsive max-width */
}

/* Inputs */
.login-input {
  width: calc(100% - 24px); /* Adjust for padding */
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Button */
.btn-outline-primary {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border-color: rgb(15, 15, 128);
  background-color: rgb(15, 15, 128);
  color: antiquewhite;
}

.btn-outline-primary:hover {
  background-color: antiquewhite;
  color: rgb(15, 15, 128);
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .login-container {
      width: 90%;
      padding: 15px;
  }

  .login-input {
      font-size: 14px;
  }
}



/* ERROR CSS */

.errorpage-section{
  height: 100vh;
  width: 100%;
  background-color: rgb(15, 15, 128);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
}

.errorpage-container{
  margin-top: 20px;
  height: 25%;
  width: 30vh;
  background-color: antiquewhite;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}


.errorpage-error{

  margin-top: 10px;
}


.pred-header-container{
  display: flex;
  justify-content: space-between;
}


.validation-container{
  margin-top: 10px;
  border-radius: 30px;
  width: 90%;
  height: 20vh;
  display: flex;
  justify-content: space-around;
  padding: 10px;
  align-items: center;
  color: antiquewhite;
  list-style: none;
  font-size: large;
}


.validation-container>ul>li{
  list-style: none;
  font-size: large;
}

.main-valid-container{
  display: flex;
  flex-direction: column;
  background-color: rgb(15, 15, 128);
  margin-top: 10px;
  border-radius: 30px;
  width: 90%;
  height: 20vh;
  justify-content: space-around;
  padding: 10px;
   align-items: center;
  color: antiquewhite;
}


@media (max-width: 768px) {
  .main-valid-container{
    width: 85%;
    height: 30vh;
  }
  .validation-container {
      display: flex;
      list-style: none;
      font-size: large;
      flex-direction: column;
      
  }

}



